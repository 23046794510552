var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deal-view-popup"},[_c('div',{staticClass:"deal-description"},[_c('p',{staticClass:"deal-description__title"},[_vm._v(_vm._s(_vm.$t("deal.report.title")))])]),(_vm.deal.completed == 0)?_c('Button',{staticClass:"btn makeReport icon-absolute-left",attrs:{"button":{
      title: _vm.$t('taskItem.addReport'),
      color: 'rgba(14, 16, 18, 0.88)',
      borderRadius: '100px',
      backgroundColor: '#1D1B20',
      color: '#fff',
      height: '40px',
      icon: 'send-2.svg',
      iconPosition: 'left',
      fontSize: '14px',
      paddingX: '15px',
    }},nativeOn:{"click":function($event){return _vm.goToMakeReport()}}}):_vm._e(),_vm._l((_vm.reports),function(item,key){return _c('ReportItem',{key:key,attrs:{"item":item},on:{"updateReportlistEmit":function($event){return _vm.getList()}}})}),_c('van-overlay',{staticClass:"van-overlay-loading",attrs:{"show":_vm.isLoading},on:{"click":function($event){_vm.isLoading = false}}},[_c('div',{staticClass:"wrapper-loading",on:{"click":function($event){$event.stopPropagation();}}},[_c('van-loading',{attrs:{"type":"spinner","color":"#1989fa"}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }