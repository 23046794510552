<template>
	<div class="calendar">
		<div class="calendar-header">
			<div class="row">
				<h2 class="calendar-month">{{ currentMonth }}</h2>
				<div class="buttons-holder">
					<button class="calendar-btn" @click="prevMonth">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M12.5 16.6004L7.0667 11.1671C6.42503 10.5254 6.42503 9.47539 7.0667 8.83372L12.5 3.40039" stroke="#1D1B20" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</button>
					<button class="calendar-btn" @click="nextMonth">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7.49997 3.39961L12.9333 8.83294C13.575 9.47461 13.575 10.5246 12.9333 11.1663L7.49997 16.5996" stroke="#1D1B20" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</button>
				</div>
			</div>
			<div class="row">
				<span class="days">{{ deal.progress_days }}/{{deal.all_days}} дней</span>
				<!-- <span class="share">
					<div class="icon-holder">
						<img src="@/assets/images/share.svg" alt="..." />
					</div>
					{{ $t("global.share") }}
				</span> -->
			</div>
		</div>
		<div class="calendar-body">
			<div v-for="(week, index) in freqWeeks" :key="index" class="body-row">
				<span>{{ index + 1 }} {{ $t("global.week").toLowerCase() }} </span>
				<div class="row-days">
					<div v-for="(day, dayIndex) in week" :key="dayIndex">
						<div class="day-circle" :class="day.complete ? 'complete' : ''">{{ dayIndex + 1 }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { startOfWeek, endOfWeek, isSameMonth, startOfMonth, endOfMonth, eachDayOfInterval, format, isSameDay } from "date-fns";

export default {
	name: "Calendar",
	props: {
		date: {
			type: Date,
			default: () => new Date()
		},
		progress: {
			type: Array,
			default: () => []
		},
		freq: {
			type: Number,
			default: null
		},
		deal: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			currentMonth: "",
			weeks: [],
			freqWeeks: [],
			weekDays: [this.$t("calendar.weekDay1"), this.$t("calendar.weekDay2"), this.$t("calendar.weekDay3"), this.$t("calendar.weekDay4"), this.$t("calendar.weekDay5"), this.$t("calendar.weekDay6"), this.$t("calendar.weekDay7")],
			currentMonthUa: ""
		};
	},
	methods: {
		prevMonth() {
			this.createCalendar(new Date(this.date.setMonth(this.date.getMonth() - 1)));
		},
		nextMonth() {
			this.createCalendar(new Date(this.date.setMonth(this.date.getMonth() + 1)));
		},
		createCalendar(date) {
			this.currentMonth = format(date, "MMMM yyyy");

			const startOfMonthDate = startOfMonth(date);
			const endOfMonthDate = endOfMonth(date);
			const startOfWeekDate = startOfWeek(startOfMonthDate, {
				weekStartsOn: 1
			});
			const endOfWeekDate = endOfWeek(endOfMonthDate, { weekStartsOn: 1 });
			const days = eachDayOfInterval({
				start: startOfWeekDate,
				end: endOfWeekDate
			}).filter((day) => isSameMonth(day, startOfMonthDate));

			let freqWeeks = [];
			const weeks = [];
			let week = [];
			let countWeekDay = 0;
			let countComplete = 0;

			days.forEach((day) => {
				week.push({
					// date: format(day, "dd") < 10 ? format(day, "dd")[1] : format(day, "dd")
				});

				this.progress.forEach((event) => {
					if (isSameDay(day, new Date(event))) {
						week[week.length - 1].compelete = true;
						countComplete++;
					}
				});

				countWeekDay++;

				if (countWeekDay === 7) {
					freqWeeks.push(countComplete);
					weeks.push(week);
					countWeekDay = 0;
					countComplete = 0;
					week = [];
				}
			});

			if (week.length > 0) {
				freqWeeks.push(countComplete);
				weeks.push(week);
			}

			while (weeks.length < 4) {
				weeks.push(Array(7).fill({ date: "", isCurrentMonth: false, compelete: false }));
			}

			this.weeks = weeks;
			this.generateWeeks(freqWeeks);
		},

		generateWeeks(freqWeeks) {
			let arr = [];
			let subArr = [];
			let count = 0;
			let completeCount = 0;

			for (let i = 0; i < this.weeks.length; i++) {
				if (completeCount === freqWeeks[i]) {
					completeCount = 0;
				}

				for (let j = 0; j < this.freq; j++) {
					if (completeCount < freqWeeks[i]) {
						subArr.push({ complete: true });
						completeCount++;
					} else {
						subArr.push({ complete: false });
					}

					count++;

					if (count === this.freq) {
						completeCount = 0;
						count = 0;
						arr.push(subArr);
						subArr = [];
					}
				}
			}

			this.freqWeeks = arr;
		}
	},

	mounted() {
		this.createCalendar(this.date);
	},

	watch: {
		progress: {
			handler(newVal, oldVal) {
				console.log("myObject changed!", newVal, oldVal);
				this.createCalendar(this.date);
			},
			deep: true
		}
	}
};
</script>

<style lang="scss" scoped>
.calendar {
	color: #1d1b20;

	.calendar-header {
		margin-bottom: 12px;

		.row {
			display: flex;
			justify-content: space-between;

			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}

		.days {
			color: rgba(29, 27, 32, 0.56);
		}

		.calendar-month {
			font-size: 16px;
			text-transform: uppercase;
		}

		.buttons-holder {
			display: flex;

			.calendar-btn {
				display: flex;
				background: transparent;
				border: none;

				&:first-child {
					margin-right: 8px;
				}
			}
		}

		.share {
			display: flex;
			align-items: center;
			color: rgba(#1d1b20, 0.56);

			.icon-holder {
				margin-right: 8px;
			}
		}
	}

	.body-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 16px;
		color: #1d1b20;
	}

	.row-days {
		display: flex;
	}

	.day-circle {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto;
		width: 47px;
		height: 47px;
		background: #f5f5f5;
		border: 1px solid rgba(29, 27, 32, 0.2);
		border-radius: 50%;

		&.complete {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.1875 5.0625L7.3125 12.9371L3.375 9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat #1d1b20;
				background-position: center;
				border-radius: 50%;
			}
		}
	}
}
</style>
