export default {
	times(number) {
		let declension;

		if (number === 0) {
			return "0";
		}

		const mod10 = number % 10;
		const mod100 = number % 100;

		if (mod100 >= 11 && mod100 <= 14) {
			declension = "раз";
		} else if (mod10 === 1) {
			declension = "раз";
		} else if (mod10 >= 2 && mod10 <= 4) {
			declension = "раза";
		} else {
			declension = "раз";
		}

		return `${number} ${declension}`;
	},

	days(days) {
		let declension;

		if (days === 0) {
			return "0 дней";
		}

		const mod10 = days % 10;

		if (mod10 === 1) {
			declension = "день";
		} else if (mod10 >= 2 && mod10 <= 4) {
			declension = "дня";
		} else {
			declension = "дней";
		}

		if (days >= 11 && days <= 14) {
			declension = "дней";
		}

		return `${days} ${declension}`;
	}
}