<template>
	<div class="calendar">
		<div class="calendar-header">
			<div class="row">
				<h2 class="calendar-month">{{ currentMonth }}</h2>
				<div class="buttons-holder">
					<button class="calendar-btn" @click="prevMonth">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M12.5 16.6004L7.0667 11.1671C6.42503 10.5254 6.42503 9.47539 7.0667 8.83372L12.5 3.40039" stroke="#1D1B20" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</button>
					<button class="calendar-btn" @click="nextMonth">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7.49997 3.39961L12.9333 8.83294C13.575 9.47461 13.575 10.5246 12.9333 11.1663L7.49997 16.5996" stroke="#1D1B20" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</button>
				</div>
			</div>
			<div class="row">
				<span class="days">{{ deal.progress_days }}/{{ deal.all_days }} дней</span>
				<!-- <span class="share">
					<div class="icon-holder">
						<img src="@/assets/images/share.svg" alt="..." />
					</div>
					{{ $t("global.share") }}
				</span> -->
			</div>
		</div>
		<table class="table">
			<!-- <thead>
				<tr>
					<th v-for="day in weekDays" :key="day" class="p-2 border-r">{{ day }}</th>
				</tr>
			</thead> -->
			<tbody>
				<tr v-for="(week, index) in weeks" :key="index">
					<td v-for="(day, dayIndex) in week" :key="dayIndex" :class="[day.isCurrentMonth ? 'current-month' : 'prev-month']" @click="clickOnDay(day)">
						<div class="day-circle" :class="day.compelete ? 'complete' : ''" @click="$emit('goToMakeReport', day)">
							{{ day.date }}
						</div>
						<!-- <span v-for="(event, index) in day.events" :key="index"> {{ event }}</span> -->
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { startOfWeek, endOfWeek, isSameMonth, startOfMonth, endOfMonth, eachDayOfInterval, format, isSameDay } from "date-fns";

export default {
	name: "Calendar",
	props: {
		date: {
			type: Date,
			default: () => new Date()
		},
		progress: {
			type: Array,
			default: () => []
		},
		deal: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			currentMonth: "",
			weeks: [],
			weekDays: [this.$t("calendar.weekDay1"), this.$t("calendar.weekDay2"), this.$t("calendar.weekDay3"), this.$t("calendar.weekDay4"), this.$t("calendar.weekDay5"), this.$t("calendar.weekDay6"), this.$t("calendar.weekDay7")],
			currentMonthUa: "",
			createdEvents: ["2023-04-08", "2023-04-09"]
		};
	},
	methods: {
		prevMonth() {
			this.createCalendar(new Date(this.date.setMonth(this.date.getMonth() - 1)));
		},
		nextMonth() {
			this.createCalendar(new Date(this.date.setMonth(this.date.getMonth() + 1)));
		},
		createCalendar(date) {
			this.currentMonth = format(date, "MMMM yyyy");
			// this.currentMonthUa = date.toLocaleDateString(undefined, { month: "long", year: "numeric" });

			const startOfMonthDate = startOfMonth(date);
			const endOfMonthDate = endOfMonth(date);
			const startOfWeekDate = startOfWeek(startOfMonthDate, {
				weekStartsOn: 1
			});
			const endOfWeekDate = endOfWeek(endOfMonthDate, { weekStartsOn: 1 });
			const days = eachDayOfInterval({
				start: startOfWeekDate,
				end: endOfWeekDate
			});

			const weeks = [];
			let week = [];
			let countWeekDay = 0;

			days.forEach((day) => {
				week.push({
					date: format(day, "dd") < 10 ? format(day, "dd")[1] : format(day, "dd"),
					isCurrentMonth: isSameMonth(day, startOfMonthDate),
					fullDate: format(day, "Y-MM-dd"),
					compelete: false
				});

				this.progress.forEach((event) => {
					if (isSameDay(day, new Date(event))) {
						// week[week.length - 1].events.push({
						// 	// title: event.title,
						// 	// start: event.start,
						// 	// end: event.end
						// 	compelete: true
						// });
						week[week.length - 1].compelete = true;
					}
				});

				countWeekDay++;

				if (countWeekDay === 7) {
					weeks.push(week);
					countWeekDay = 0;
					week = [];
				}
			});

			weeks.push(week);
			this.weeks = weeks;
		},
		clickOnDay(day) {
			// console.log(day);
		},
		eventDateToHour(value) {
			return format(value, "HH:mm");
		}
	},

	mounted() {
		this.createCalendar(this.date);
	},

	watch: {
		progress: {
			handler(newVal, oldVal) {
				this.createCalendar(this.date);
			},
			deep: true
		}
	}
};
</script>

<style lang="scss" scoped>
.calendar {
	color: #1d1b20;

	.calendar-header {
		margin-bottom: 12px;

		.row {
			display: flex;
			justify-content: space-between;

			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}

		.days {
			color: rgba(29, 27, 32, 0.56);
		}

		.calendar-month {
			font-size: 16px;
			text-transform: uppercase;
		}

		.buttons-holder {
			display: flex;

			.calendar-btn {
				display: flex;
				background: transparent;
				border: none;

				&:first-child {
					margin-right: 8px;
				}
			}
		}
	}

	.table {
		width: 100%;
		font-size: 16px;

		tbody {
			td {
				text-align: center;
			}
		}

		.current-month {
			.day-circle {
				margin: auto;
				background: #f5f5f5;
				border: 1px solid rgba(29, 27, 32, 0.2);
				border-radius: 50%;
			}
		}

		.day-circle {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 47px;
			height: 47px;
			margin: auto;

			&.complete {
				position: relative;

				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.1875 5.0625L7.3125 12.9371L3.375 9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat #1d1b20;
					background-position: center;
					border-radius: 50%;
				}
			}
		}

		.prev-month {
			opacity: 0.24;
		}
	}
}
</style>
