<template>
  <div class="deal-view-popup">
    <div class="deal-description">
      <div class="deal-description-content">
        <div class="deal-description-content-head">
          <p class="deal-description__title">{{ $t("deal.info.title") }}</p>
          <span class="share" @click="copyUrl()">
            <div class="icon-holder">
              <img src="@/assets/images/share.svg" alt="..." />
            </div>
            {{ $t("global.share") }}
          </span>
        </div>

        <div class="deal-description__text-wrapper">
          <span class="deal-description__text" :class="descriptionTextStyle">
            <span v-html="textCopy"></span>
          </span>

          <div
            class="deal-description__btn"
            @click="showAllText()"
            v-if="deal.description.length >= setting.maxTextLen && isHideBtn"
          >
            ещё
          </div>
        </div>
        <ul class="tags">
          <li v-if="deal.start_type != 0" class="tag">
            🧨 с {{ deal.start_date }}
          </li>

          <li class="tag">⏳ {{ frequency }}</li>
          <li class="tag" v-if="deal.type == 0">🗓 {{ freq }}</li>
          <!-- <li class="tag">💸 10$</li> -->
        </ul>
      </div>
      <Button
        class="btn"
        v-if="getDealStatusInfo != 'hide'"
        @click.native="handle()"
        :button="{
          title: getDealStatusInfo,
          color: 'rgba(14, 16, 18, 0.88)',
          borderRadius: '100px',
          backgroundColor: '#1D1B20',
          color: '#fff',
          height: '40px',
          fontSize: '14px',
          paddingX: '15px',
        }"
      />
      <Button
        v-if="deal.my"
        class="btn"
        @click.native="handleStatus()"
        :button="{
          title: deal.status == 0 ? 'Завершить дело' : 'Возобновить',
          color: 'rgba(14, 16, 18, 0.88)',
          borderRadius: '100px',
          backgroundColor: '#1D1B20',
          color: '#fff',
          height: '40px',
          fontSize: '14px',
          paddingX: '15px',
        }"
      />
      <Button
        class="btn btn-complaint"
        @click.native="handleComplaint()"
        :button="{
          title: 'Пожаловаться',
          borderRadius: '100px',
          backgroundColor: 'transparent',
          height: '40px',
          fontSize: '14px',
          paddingX: '15px',
        }"
      />
    </div>
    <van-overlay :show="isLoading">
      <div class="wrapper">
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
    <van-popup
      v-model="showPopoverDelete"
      position="bottom"
      class="popup-task-delete"
    >
      <FinishDeal
        v-if="showPopoverDelete"
        :prevData="deal"
        @cancel="showPopoverDelete = false"
        @updateReportlistEmit="$emit('updateReportlistEmit')"
      />
    </van-popup>
  </div>
</template>

<script>
import Button from "@/components/Global/Button.vue";
import FinishDeal from "@/components/Task/FinishDeal.vue";
import ApiDeal from "@/api/deals";
import Declination from "@/helpers/declination";
import { eventBus } from "@/main";
export default {
  props: {
    deal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      textCopy: this.deal.description,
      descriptionTextStyle: "text-normal",
      isHideBtn: true,
      isLoading: false,
      showPopoverDelete: false,
      setting: {
        maxTextLen: 200,
      },
    };
  },
  watch: {
    deal() {
      this.textCopy = this.deal.description;
    },
  },
  computed: {
    frequency() {
      if (this.deal.type === 0) {
        return "Каждый день";
      } else if (this.deal.type === 1) {
        return Declination.times(this.deal.freq);
      }
    },
    freq() {
      if (this.deal.freq === 0) {
        return "Бессрочно";
      } else {
        return Declination.days(this.deal.freq);
      }
    },
    getDealStatusInfo() {
      if (this.deal.my) {
        return `Редактировать`;
      } else {
        if (this.deal.status === 0 && this.deal.joined === 0) {
          return `Я в деле`;
        } else if (this.deal.status === 0 && this.deal.joined === 1) {
          return `Завершить`;
        }
      }
      return "hide";
    },
  },
  components: {
    Button,
    FinishDeal,
  },
  methods: {
    handleComplaint() {
      ApiDeal.complaintDeals(this.deal.id).then((response) => {
        this.$notify({
          message: "Жалоба отправлена",
          type: "error",
        });
      });
    },
    copyUrl() {
      this.$toast({
        message: "Ссылка скопирована",
        type: "success",
        className: "large-toast",
      });
      navigator.clipboard.writeText(
        `https://t.me/vmeste_app_bot?start=${this.deal.id}`
      );
    },
    handle() {
      // this.isLoading = true;
      if (!this.deal.my) {
        // Я в деле
        if (this.deal.status === 0 && this.deal.joined === 0) {
          ApiDeal.enjoy(this.deal.id).then((response) => {
            this.isLoading = false;
            if (response.err == 0) {
              this.$emit("updateDeal");
              eventBus.$emit("wasJoined");
            }
          });
        }

        if (this.deal.status === 0 && this.deal.joined === 1) {
          ApiDeal.finish(this.deal.id).then((response) => {
            // ApiDeal.enjoy(this.deal.id).then((response) => {
            this.isLoading = false;
            if (response.err == 0) {
              this.$emit("updateDeal");
            }
            // });
          });
        }
      }
      if (this.deal.my) {
        document.title = "Редактирование дела";
        eventBus.$emit("changeStep", {
          step: "",
          form: this.deal,
        });
        eventBus.$emit("openCreateForm");
      }
    },
    showAllText() {
      this.isHideBtn = false;
      this.textCopy = this.deal.description;
      // refShollAllText.value.style.display = "none";
    },
    handleStatus() {
      let form = {
        status: 0,
        deal_id: this.deal.id,
        id: this.deal.id,
      };
      const e = this.$t("create.edit");
      if (this.deal.status == 0) {
        this.showPopoverDelete = true;
        return;
      }

      ApiDeal.editDeals(form).then((responce) => {
        if (responce.err.length && responce.err[0] != 0) {
          this.$notify({
            type: "danger",
            message: responce.err_msg[0],
          });
        } else {
          this.$emit("updateDeal");
          this.$notify({
            type: "success",
            message: e,
          });
        }
      });
    },
  },
  mounted() {
    if (this.deal.description.indexOf("\n") != -1) {
      this.descriptionTextStyle = "text-pre";
    }
    if (this.deal.description.length >= this.setting.maxTextLen) {
      this.textCopy =
        this.deal.description.slice(0, this.setting.maxTextLen) + "...";
    } else {
      this.textCopy = this.deal.description;
    }
  },
};
</script>
<style lang="scss" scoped>
.deal-view-popup {
  position: relative;
  overflow: hidden;
}
.deal-description {
  flex-direction: column;
  &-content {
    width: 100%;
  }
}
.btn {
  width: 100%;
  margin-top: 40px;
}
.btn + .btn {
  margin-top: 15px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.deal-description-content-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-complaint {
  color: #1d1b20;
  opacity: 0.5;
}
</style>
