var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deal-page",style:({ backgroundColor: _vm.getDealBackground })},[_c('div',{staticClass:"container"},[_c('div',[_c('div',{staticClass:"page-header"},[_c('IconButton',{staticClass:"index-100",attrs:{"van-button":{ background: 'rgba(14, 16, 18, 0.16)' },"van-icon":{ name: 'arrow-left', size: 20, color: '#0E1012' }},on:{"click":function($event){return _vm.btnBack()}}}),_c('Button',{attrs:{"button":{
            title: _vm.getDealStatusInfo('title'),
            color: 'rgba(14, 16, 18, 0.88)',
            borderRadius: '100px',
            backgroundColor: '#F5F5F5',
            height: '40px',
            fontSize: '14px',
            paddingX: '15px',
            icon: _vm.getDealStatusInfo('icon'),
            iconPosition: 'left',
          }}})],1),_c('div',{staticClass:"intro-section"},[_c('van-skeleton',{attrs:{"title":"","avatar":"","row":3,"loading":_vm.isLoading}},[_c('div',{staticClass:"inner-holder"},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.deal.title))]),(_vm.deal.user && _vm.deal.user !== null)?[_c('div',{staticClass:"text-holder author",on:{"click":function($event){$event.preventDefault();return _vm.getUser(_vm.deal.user)}}},[_c('p',[_vm._v(" Вместе с "),_c('span',[_vm._v(_vm._s(_vm.deal.user.first_name)+" "+_vm._s(_vm.deal.user.last_name))])])]),(_vm.deal.members && _vm.deal.members.length > 0)?_c('div',{staticClass:"text-holder together-wrapper",on:{"click":function($event){return _vm.$router.push(`/deal/${_vm.$route.params.id}/participant`)}}},[_c('p',[_vm._v(" "+_vm._s(_vm.deal.acitive_users < 3 ? "и еще" : `и еще ${_vm.deal.acitive_users}`)+" ")]),_c('ul',{staticClass:"together-list"},_vm._l((_vm.deal.members),function(member,index){return _c('li',{key:index,staticClass:"list-item"},[_c('img',{attrs:{"src":member,"alt":"..."}})])}),0)]):_vm._e()]:[(_vm.deal.members && _vm.deal.members.length > 0)?_c('div',{staticClass:"text-holder together-wrapper",on:{"click":function($event){return _vm.$router.push(`/deal/${_vm.$route.params.id}/participant`)}}},[_c('ul',{staticClass:"together-list"},[_c('li',{staticClass:"list-item no-author"},[_c('img',{attrs:{"src":_vm.deal.members[0],"alt":"..."}})])]),_c('p',[_vm._v(" "+_vm._s(`и еще ${_vm.deal.acitive_users}`)+" ")])]):_vm._e()]],2),_c('div',{staticClass:"buttons-holder"},[(_vm.deal.chat)?_c('Button',{attrs:{"button":{
                title: 'Перейти в чат',
                color: 'rgba(29, 27, 32, 0.88)',
                borderRadius: '100px',
                backgroundColor: 'transparent',
                height: '40px',
                fontSize: '16px',
                paddingX: '17px',
                borderColor: 'rgba(14, 16, 18, 0.16)',
                icon: _vm.getDealStatusInfo('tg'),
                iconPosition: 'left',
              }},nativeOn:{"click":function($event){return _vm.goTo(_vm.deal.chat)}}}):_vm._e(),(_vm.deal.channel)?_c('Button',{attrs:{"button":{
                title: 'Перейти в канал',
                color: 'rgba(29, 27, 32, 0.88)',
                borderRadius: '100px',
                backgroundColor: 'transparent',
                height: '40px',
                fontSize: '16px',
                paddingX: '17px',
                borderColor: 'rgba(14, 16, 18, 0.16)',
                icon: _vm.getDealStatusInfo('tg'),
                iconPosition: 'left',
              }},nativeOn:{"click":function($event){return _vm.goTo(_vm.deal.channel)}}}):_vm._e()],1)]),_c('div',{staticClass:"buttons-holder tabs-btn"},_vm._l((_vm.tabs),function(item,key){return _c('Button',{key:key,class:{
              activeTab: _vm.activeTab == key,
            },attrs:{"button":{
              color: 'rgba(29, 27, 32, 0.88)',
              borderRadius: '100px',
              backgroundColor: 'transparent',
              height: '40px',
              fontSize: '16px',
              paddingX: '17px',
              borderColor: 'rgba(14, 16, 18, 0.16)',
              icon: item.icon,
              iconPosition: 'center',
            }},nativeOn:{"click":function($event){return _vm.openPopup(item, key)}}})}),1)],1)]),(_vm.showPopover)?_c('div',{staticClass:"deal-view-popup-container"},[_c(_vm.dealTabs,{tag:"component",attrs:{"deal":_vm.deal,"formData":_vm.formData},on:{"updateDeal":function($event){return _vm.getDeal()},"openignReport":_vm.openignReport}})],1):_vm._e()]),_c('ModalList',{ref:"modalParticipants",attrs:{"title":"Участники"}},[_c('Participants',{attrs:{"deal-id":_vm.$route.params.id}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }