<template>
  <div class="deal-view-popup">
    <Calendar
      v-if="type === 0"
      :progress="progress"
      :deal="deal"
      @goToMakeReport="handleGoToMakeReport"
    />
    <CalendarWeeks v-else :progress="progress" :freq="deal.freq" :deal="deal" />
    <van-overlay
      :show="isLoading"
      @click="isLoading = false"
      class="van-overlay-loading"
    >
      <div class="wrapper-loading" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import ApiDeal from "@/api/deals";
import Calendar from "@/components/Global/Calendar";
import CalendarWeeks from "@/components/Global/CalendarWeeks";
import { eventBus } from "@/main";

export default {
  props: {
    deal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      progress: ["2023-02-11", "2023-02-12"],
      isLoading: false,
      date: null,
      type: null,
    };
  },
  computed: {},
  components: { Calendar, CalendarWeeks },
  methods: {
    handleGoToMakeReport(item) {
      // eventBus.$emit('openMakeReportWithDate', item)
    },
    getProgress() {
      // this.isLoading = true;
      ApiDeal.getProgress(this.deal.id)
        .then((responce) => {
          if (responce.err[0] == 0) {
            this.progress = responce.data;
          } else {
            this.$notify(responce.err_msg[0]);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getProgress();

    this.type = this.deal.type;
    // console.log(this.deal.type, "deal");
  },
};
</script>
<style lang="scss" scoped>
.deal-view-popup {
  position: relative;
  overflow: hidden;
}
.btn {
  width: 100%;
  margin-top: 40px;
}
</style>
